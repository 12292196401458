<template>
  <v-row>
    <v-col cols="12">
      <base-card>
        <v-card-title>
          Earthquake
          <v-spacer />
          <v-tooltip top>
            <template v-slot:activator="{ on, attrs }">
              <v-btn
                color="primary"
                dark
                v-bind="attrs"
                v-on="on"
                fab
                small
                :to="{ path: 'addGempa' }"
              >
                <v-icon dark> mdi-plus </v-icon>
              </v-btn>
            </template>
            <span>Add</span>
          </v-tooltip>
        </v-card-title>
        <v-card-text>
          <v-simple-table dense>
            <template v-slot:default>
              <thead>
                <tr>
                  <th class="text-left">Datetime</th>
                  <th class="text-left">Latitude</th>
                  <th class="text-left">Longitude</th>
                  <th class="text-left">Magnitude</th>
                  <th class="text-left">MMI</th>
                  <th class="text-left">Action</th>
                </tr>
              </thead>
              <tbody>
                <tr
                  v-for="item in listGempa"
                  :key="item.DATE_TIME + '_' + item.LAT + '_' + item.LON"
                >
                  <td>{{ formatCompat(item.DATE_TIME) }}</td>
                  <td>{{ item.LAT }}</td>
                  <td>{{ item.LON }}</td>
                  <td>{{ item.MAG }}</td>
                  <td>{{ item.MMI }}</td>
                  <td>
                    <div class="flex">
                      <v-tooltip top>
                        <template v-slot:activator="{ on, attrs }">
                          <v-btn
                            color="danger"
                            dark
                            v-bind="attrs"
                            v-on="on"
                            icon
                            @click.prevent="openMyDialog(item)"
                          >
                            <v-icon>mdi-trash-can-outline</v-icon>
                          </v-btn>
                        </template>
                        <span>Delete</span>
                      </v-tooltip>
                    </div>
                  </td>
                </tr>
              </tbody>
            </template>
          </v-simple-table>
          <v-col cols="12">
            <div id="pagination">
              <v-pagination
                v-model="current"
                :length="paginate_total"
                :total-visible="7"
                prev-icon="mdi-menu-left"
                next-icon="mdi-menu-right"
                circle
                @input="updateCurrent"
              ></v-pagination>
            </div>
          </v-col>
        </v-card-text>
      </base-card>
    </v-col>
    <v-dialog v-model="dialog" persistent max-width="600px">
      <v-card>
        <v-card-title class="red">
          <span class="text-h5 white--text">Delete Earthquake</span>
        </v-card-title>
        <v-card-text>
          <v-container>
            <v-row>
              <v-col cols="12">
                <p class="mb-0 mt-5">Are you sure delete this earthquake?</p>
                <v-text-field v-model="dateDel" v-show="false"></v-text-field>
                <v-text-field v-model="latDel" v-show="false"></v-text-field>
                <v-text-field v-model="lonDel" v-show="false"></v-text-field>
              </v-col>
            </v-row>
          </v-container>
        </v-card-text>
        <v-card-actions>
          <v-btn class="white--text" color="red darken-2" @click="deleteGempa">
            Delete
          </v-btn>
          <v-spacer></v-spacer>
          <v-btn
            class="white--text"
            color="grey darken-2"
            @click="dialog = false"
          >
            Cancel
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </v-row>
</template>
<script>
import axios from "axios";
import { mapGetters } from "vuex";
export default {
  metaInfo: {
    // title will be injected into parent titleTemplate
    title: "Earthquake",
  },
  data() {
    return {
      listGempa: [],
      current: 1,
      paginate_total: 0,
      dateDel: "",
      latDel: "",
      lonDel: "",
      dialog: false,
    };
  },
  created: function () {
    this.getProducts();
    const gemas_token = this.$cookies.get("gemas_token");
    console.log("----" + gemas_token);
  },
  computed: {
    ...mapGetters(["GEMAS_BACKEND"]),
    axiosParams() {
      const params = new URLSearchParams();
      params.append("pagin", this.current);
      return params;
    },
  },
  methods: {
    // Get Product
    getProducts: function () {
      axios
        .get(
          "https://gemas.penggiat-riset2.com/api/kejadian_gempa/apiGetKejadianGempa",
          {
            params: this.axiosParams,
          }
        )
        .then((res) => {
          this.listGempa = res.data.kejadian_gempa;
          this.paginate_total = Math.ceil(
            res.data.total / res.data.limit_per_page
          );
          console.log(this.listGempa);
        })
        .catch((err) => {
          // handle error
          console.log(err);
        });
    },
    updateCurrent(i) {
      this.current = i;
      this.getProducts();
    },
    formatCompat(date) {
      var d = new Date(date);
      var ms = [
        "Jan",
        "Feb",
        "Mar",
        "Apr",
        "May",
        "Jun",
        "Jul",
        "Aug",
        "Sep",
        "Oct",
        "Nov",
        "Dec",
      ];
      return d.getDate() + " " + ms[d.getMonth()] + " " + d.getFullYear();
    },
    openMyDialog(item) {
      this.dateDel = item.DATE_TIME;
      this.latDel = item.LAT;
      this.lonDel = item.LON;
      this.dialog = true
    },
    deleteGempa: function () {
      let postData = new FormData();
      postData.append("DATE_TIME", this.dateDel);
      postData.append("LAT", this.latDel);
      postData.append("LON", this.lonDel);
      axios
        .post(this.GEMAS_BACKEND + "kejadian_gempa/apiDeleteKejadianGempa", postData)
        .then((res) => {
          this.dialog = false;
          this.getProducts();
          console.log(this.listGempa);
        })
        .catch((err) => {
          // handle error
          console.log(err);
        });
    },
  },
};
</script>
